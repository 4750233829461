<i18n locale="th" lang="yaml" src="@i18n/drone/drone.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
page.title : "{name} :: เปลี่ยนเจ้าของ"
page.header : "เปลี่ยนแปลงเจ้าของ"
page.description : "ทำการเปลี่ยนแปลงข้อมูลผู้ใช้/ผู้ซื้อ ของ Drone นี้ เพื่อเก็บข้อมูลอ้างอิงในระบบ"

register.select_customer.label : "ระบุเจ้าของเครื่องใหม่"
register.submit.label: "ยื่นเปลี่ยนข้อมูลลงทะเบียน"

register.confirm.title : "ยื่นขอเปลี่ยนแปลง"
register.confirm.message : ยื่นขอเปลี่ยนแปลงข้อมูลสำหรับ Drone {name}, หลังจากยืนยันแล้ว การแก้ไขข้อมูลเหล่านี้ต้องแจ้งทางระบบเท่านั้น"
register.success : "ทำการเปลี่ยนแปลงข้อมูลให้ Drone {name} สำเร็จ"

register.error.invalid_status : "กรุณาใช้เมนู ลงทะเบียน แทน"

register.customer.title : "ระบุเจ้าของเครื่อง"

drone.field.customer.old : "เจ้าของเดิม"
drone.field.customer.new : "เจ้าของเครื่องใหม่"

drone.field.customer.help : "เจ้าของเครื่องที่ลงทะเบียน"
drone.field.latest_flight_time_sec.help : "ค่า Flight Time ของ Drone ตัวนี้"

field.changed_date : "วันที่เปลี่ยนเจ้าของ"
field.changed_date.help : "วันที่ขายหรือโอนชื่อเจ้าของ"
field.reason : "สาเหตุการเปลี่ยน"
field.reason.placeholder : "เช่น เจ้าของขายเครื่องต่อ หรือ โอนความเป็นเจ้าของให้นักบิน"
</i18n>

<template>
	<div class="page">
		<my-page-header
			:title="$t('page.header')"
			:description="$t('page.description')"/>

		<a-card :bordered="false">
			<a-form
				layout="horizontal"
				:label-col="labelCol"
				:wrapper-col="wrapperCol"
				:form="formObj"
				@submit.prevent="handleSubmit">

				<a-form-item :label="$t('drone.field.customer.old')">
					<span class="ant-form-text">
						{{droneOwner.name}}
					</span>
				</a-form-item>
				<hr />
				<a-form-item :label="$t('drone.field.customer.new')" :required="true"
					:validate-status="$notEmpty(selectedCustomerMessage) ? 'error' : ''"
					:help="selectedCustomerMessage"
					:extra="$t('drone.field.customer.help')">
					<a-button  icon="user" type="dashed" @click="handleSelectCustomer">
						{{$t('register.select_customer.label')}}
					</a-button>
					<CustomerLiteCard v-show="selectedCustomer.mode != 'none'"
						size="small"
						:customer="selectedCustomer.customer"
						:bordered="true"
						class="register-customer-card"/>
				</a-form-item>
				<a-form-item :label="$t('field.changed_date')"
					:extra="$t('field.changed_date.help')">
					<MyDatePicker
						v-decorator="['changed_date',
								{
									rules: [
										{ required: true ,message: $tt('validate.required','field.changed_date') },
									],
								}]"
						format="DD MMMM YYYY"/>
				</a-form-item>
				<a-form-item :label="$t('drone.field.latest_flight_time_sec')"
					:extra="$t('drone.field.latest_flight_time_sec.help')">
					<FlightTimeInput
						v-decorator="[
							'latest_flight_time_sec' ,
							{
								rules: [
								{ required: true ,message: $tt('validate.required','drone.field.latest_flight_time_sec') },
							] },
						]"/>
				</a-form-item>
				<hr />
				<a-form-item :label="$t('field.reason')">
					<a-textarea
						v-decorator="[
							'reason' ,
							{
								rules: [
								{ required: true ,message: $tt('validate.required','field.reason') },
								],
							},
						]"
						:auto-size="{ minRows: 3,maxRows: 5 }"
						:placeholder="$t('field.reason.placeholder')">
					</a-textarea>
				</a-form-item>

				<a-form-item v-bind="wrapperOnlyLayout">
					<a-button
						:disabled="formLoading"
						icon="left"
						size="large"
						:style="{ 'margin-right': '4px' }"
						@click="goBack">
						{{$t('common.back')}}
					</a-button>
					<a-button
						:loading="formLoading"
						type="info"
						html-type="submit"
						class="btn-submit"
						icon="file-add"
						size="large">
						{{$t('register.submit.label')}}
					</a-button>
				</a-form-item>
			</a-form>
		</a-card>

		<CustomerModal
			v-model="selectedCustomer"
			:modal-title="$t('register.customer.title')"
			:visible="visible"
			:excluded-list="excludedCustomers"
			:can-create="true"
			@select="handleSelectModal"
			@close="handleCloseModal"/>
	</div>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import FlightTimeInput from "@components/input/FlightTimeInput.vue"
import CustomerModal from "@components/customer/CustomerModal.vue"
import CustomerLiteCard from "@components/customer/CustomerLiteCard.vue"
import MyDatePicker from "@components/input/MyDatePicker.vue"
import {updateFieldsValue,assignFieldErrors} from "@utils/formUtil"
import axios from "axios"
import {emptyDrone} from "@utils/objectUtil"
import ModelError from '@utils/errors/ModelError'

export default {
	components : {
		CustomerModal , CustomerLiteCard ,
		FlightTimeInput , MyDatePicker
	} ,
	mixins : [HasAntdFormMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name:this.drone.serial_no})
		}
	} ,
	props : {
		droneData : {
			type : null,
			default : emptyDrone()
		} ,
	} ,
	data() {
		return {
			selectedCustomerMessage : null,
			formLoading : false ,
			visible : false,
			selectedCustomer : {
				mode : 'none' ,
				customer : {}
			},
		}
	} ,
	computed : {
		drone() {
			return this.droneData.drone ? this.droneData.drone : {}
		} ,
		droneLot() {
			return this.droneData.lot ? this.droneData.lot : {}
		} ,
		droneModel() {
			return this.droneData.model ? this.droneData.model : {}
		} ,
		droneOwner() {
			return this.droneData.owner ? this.droneData.owner : {}
		} ,
		excludedCustomers() {
			return [this.droneOwner]
		}
	} ,
	watch : {
		droneData : {
			handler() {
				this.preProcess()
			} ,
			deep: true
		}
	} ,
	mounted(){
		this.preProcess()
	} ,
	methods : {
		preProcess() {
			if (!this.$authorize('changeRegister','drone')) {
				this.$pageError({name : 'error_forbidden'})
				return
			}
			if (this.drone.id > 0) {
				if (this.drone.drone_status != 'active') {
					this.$pageError({name : 'error_forbidden'},{message : this.$t("register.error.invalid_status")})
				} else {
					updateFieldsValue(this.formObj,{latest_flight_time_sec : this.drone.latest_flight_time_sec})
				}
			}

		} ,
		handleSelectCustomer() {
			this.visible = true
		} ,
		handleCloseModal() {
			this.visible = false
		} ,
		handleSelectModal(selected) {
			if (selected.mode == 'none') {
				this.selectedCustomerMessage = this.$tt('validate.required','drone.field.customer')
			} else {
				this.selectedCustomerMessage = null;
			}
			this.handleCloseModal()
		} ,
		handleSubmit() {
			// validate before confirm
			this.formObj.validateFields((err,values) => {
				if (this.selectedCustomer.mode == 'none') {
					this.selectedCustomerMessage = this.$tt('validate.required','drone.field.customer')
				} else {
					this.selectedCustomerMessage = null;
				}
				if (err || this.selectedCustomerMessage) {
					return
				}
				this.$confirm({
					title : this.$t('register.confirm.title') ,
					content : this.$t('register.confirm.message',{name: this.drone.name}) ,
					maskClosable : true,
					onOk: () => {
						this.formLoading = true
						const postData = {
							customer : this.selectedCustomer.customer,
							change_register : values
						}
						axios.post('/api/drones/'+this.drone.id+'/change-register-request',postData).then((response)=> {

							this.$message.success(this.$t('register.success',{"name" : this.drone.name}))
							this.$open({name:"drone/view" , params : {serial_no : this.drone.serial_no}},'_self',true)
						}).catch((error) => {
							if (error instanceof ModelError) {
								if (error.getFieldErrorsByModelKey('customer')) {
									this.selectedCustomerMessage = this.$tt('validate.invalid','drone.field.customer')
								}
								const registerErrors = error.getFieldErrorsByModelKey('register');
								if (registerErrors) {
									assignFieldErrors(this.formObj,registerErrors.field_errors)
								}
								this.displayValidateErrorMessage()
							} else {
								this.formSubmitErrors(error)
							}
						}).finally(()=> {
							this.formLoading = false
						});
					}
				})
			})
		} ,
		goBack() {
			this.$open({name:"drone/view" , params : {serial_no : this.drone.serial_no}})
		}
	}
}
</script>

<style lang="less" scoped>
.register-customer-card{
	max-width: 450px;
	margin-top : 8px;
}
</style>
